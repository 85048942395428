<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <el-switch
        :active-text="$t('system.yes')"
        :inactive-text="$t('system.no')"
        active-color="#13ce66"
        class="switch--color-bool-text"
        inactive-color="#ff4949"
        v-model="value"
    />
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])" />
    </small>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  name: 'CreateOrUpdate_switch',
  mixins: [abstractForm],
  components: {BaseFormItem},
  created() {
    // console.log('this.value111', !this.value, !!this.value)
    this.value = !!this.value;
    // console.log('this.value222', this.value)
    // if (!this.value) {
    //   this.value = false
    // }
  },
};
</script>

<style scoped>

</style>
